import React from "react"
import desarrolloCss from "../../css/desarrollo.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DataBaseIMG from "../../components/images/databases/databases"
import MenuDesarrollo from "../../components/menuDesarrollo/menuDesarrollo"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const DataBases = () => (
  <>
    <div className={desarrolloCss.background}>
      <div className={desarrolloCss.gradient}></div>
    </div>
    <DataBaseIMG></DataBaseIMG>
    <Link to="/" className={desarrolloCss.logoBlanco}>
    <img src={LogoBlanco} alt="Logotipo de engrane version blanca"/>
    </Link>
    <MenuDesarrollo></MenuDesarrollo>
    <div className={desarrolloCss.container}>
      <Layout siteTitle="Desarrollo">
        <SEO title="Bases de Datos" />
        <div className={desarrolloCss.contenido}>
          <h1 className={desarrolloCss.titulo}>
          base de <br/>
datos
          </h1>
          <p className={desarrolloCss.text}>
          ¡Nosotros sabemos que la información en tu organización es algo
muy valioso para ti! Por esa razón debe ser tratada y organizada de la mejor
manera. No te permitas perder datos importantes, nosotros podemos
administrar tu base de datos *relacionales en cualquiera de los siguientes
sistemas gestores de bases de datos.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={desarrolloCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default DataBases
